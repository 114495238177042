/* eslint-disable max-len */
import React, { Fragment, useCallback, useMemo, useState } from 'react';

import { Transition, Listbox, Disclosure } from '@headlessui/react';
import { omit } from 'lodash';
import { ClassNameValue } from 'tailwind-merge';

import { Button } from 'components/common-n4/button';
import ImageWrapper from 'components/common-n4/image';
import SimplePrice from 'components/widgets/simple-price';
import { useAb } from 'hooks';
import IconArrowRight from 'images/icon-arrow-right.svg';
import IconChevronDownSm from 'images/icon-chevron-down-sm.svg';
import IconChevronUp from 'images/icon-chevron-up.svg';
import TrustPilotStar from 'images/trustpilot-star.svg';
import { utmize } from 'lib/ts-utils';
import { tw } from 'lib/utils';
import IconCircleCheck from 'styles/icons-component/sharp-solid/circle-check.svg';
import type { Offer, OfferLevel, Release, Seller } from 'types/release';

const event = 'AffiliateAction';
const origin = 'product-prices';
const elementType = 'Button';

interface PanelVariantProps {
  seller: Seller;
  className?: ClassNameValue;
  offer: Offer;
  releaseName: string;
  releaseSlug: string;
  campaign: string;
}

interface OfferLinkProps {
  link: string;
  seller: string;
  partnerType: string;
  random: string;
  substitutions: Record<string, string>;
}

const PanelVariantOne: React.FC<PanelVariantProps> = ({ className, seller, offer, releaseName, releaseSlug, campaign }) => {
  const [clicked, setClicked] = useState(0);
  const [offerLink, setOfferLink] = useState<OfferLinkProps>({ link: '', seller: '', partnerType: '', random: '0', substitutions: {} });

  const { text } = useAb({
    skip: !offer || !offerLink.link,
    uuid: String(offer?.id),
    testName: 'price-buttons-offer',
    event,
    origin,
    forceCta: 'click_text',
    forceText: offer.clickText, // override a/b test
    clicked,
    eventProperties: { elementType, ...omit(offerLink, 'substitutions') },
    substitutions: offerLink.substitutions,
    fallbackText: 'View Details',
  });

  React.useEffect(() => {
    const random = String(Math.floor(Math.random() * 10 ** 8));
    const link = offer?.url ? utmize(offer.url, origin, random, campaign) : '';
    const partnerType = seller?.type || '';
    setOfferLink({ link, seller: seller?.name, partnerType, random, substitutions: { release: releaseName, seller: seller?.name || '' } });
  }, [offer, seller, releaseName, campaign]);

  return (
    <>
      <div className="mt-3 flex items-center justify-between @2xl/prices:mt-0">
        {seller.logo && offer && offerLink.link ? (
          <SimplePrice
            uuid={`logo-${offer.id}`}
            url={offerLink.link}
            position="offer-logo"
            origin={origin}
            releaseName={releaseName}
            slug={releaseSlug}
            seller={seller.name}
            partnerType={seller.type}
            forceText="logo"
            forceCta="logo"
            display="link"
            campaign={campaign}
          >
            <ImageWrapper
              image={seller.logo}
              imgProps={{ className: '!static !w-[124px] @2xl/prices:!w-[178px]', alt: `logo with link to ${seller.name} offer` }}
              origin="Prices"
            />
          </SimplePrice>
        ) : (
          <>
            {seller.logo && (
              <ImageWrapper
                image={seller.logo}
                imgProps={{ className: '!static !w-[124px] @2xl/prices:!w-[178px]', alt: `${seller.name} logo` }}
                origin="Prices"
              />
            )}
          </>
        )}
        <span className="hidden text-[16px] leading-[140%] tracking-tight text-navy/60 @2xl/prices:block @2xl/prices:pr-[60px] @5xl/prices:hidden">
          {seller?.tagline}
        </span>

        {seller.rating && (
          <div className="flex items-center @2xl/prices:hidden">
            <TrustPilotStar width="23" className="shrink-0" />
            <span className="ml-[10px] text-[14px] -tracking-[0.42px]">{seller.rating} out of 5</span>
          </div>
        )}
      </div>
      <div className={tw('flex flex-col justify-between gap-6 @5xl/prices:flex-row @5xl/prices:gap-12', className)}>
        <div className="mt-5 flex flex-col gap-5 @5xl/prices:basis-[578px]">
          <p className="font-light leading-[130%] -tracking-[0.42px] @2xl/prices:text-[14px] @2xl/prices:leading-[140%] @5xl/prices:text-[18px] @5xl/prices:-tracking-[0.54px]">
            {seller.blurb}
          </p>
          <div className="flex items-center gap-5">
            <Button.LinkPrimary
              shouldOpenNewTab
              onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                e.stopPropagation();
                setClicked((count) => count + 1);
              }}
              href={offerLink.link}
              className="mt-0 min-h-0 w-full px-[12px] py-[6px] text-[14px] leading-[140%] tracking-tight @2xl/prices:text-[16px] laptop:w-auto laptop:min-w-0 laptop:px-[18px] laptop:py-[10px]"
            >
              {text}
            </Button.LinkPrimary>
            {seller.reviews > 0 && (
              <div className="flex text-[12px] font-light text-black">
                See the {seller.reviews} review{seller.reviews === 1 ? '' : 's'} on <TrustPilotStar width="15" className="ml-[13px] mr-1" /> Trustpilot
              </div>
            )}
          </div>
          <div className="text-[12px] font-light text-black">Disclaimer: {offer.disclaimer}</div>
        </div>
        <hr className="border-navy/10 @5xl/prices:hidden" />
        <div className="@5xl/prices:basis-[542px]">
          <div className="text-[14px] font-light leading-[140%] tracking-tight text-navy/60">Features</div>
          <ul className="mt-5 grid gap-[12px] @2xl/prices:grid-cols-2 @5xl/prices:gap-5">
            {seller.features?.map((feature) => (
              <li key={feature} className="flex gap-3">
                <IconCircleCheck width="24" height="24" fill="#179F2D" className="shrink-0" />
                <span className="text-[16px] leading-[140%] tracking-tight @2xl/prices:text-[17px] sm:text-md">{feature}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

interface ProviderRowProps {
  offer: Offer;
  seller: Seller;
  className: ClassNameValue;
  index: number;
  releaseName: string;
  releaseSlug: string;
  defaultOpen: boolean;
  isProductPage: boolean;
  campaign: string;
}

const ProviderRow: React.FC<ProviderRowProps> = ({ offer, seller, className, index, releaseName, releaseSlug, defaultOpen, isProductPage, campaign }) => {
  const [clicked, setClicked] = useState(0);
  const [offerLink, setOfferLink] = useState<OfferLinkProps>({ link: '', seller: '', partnerType: '', random: '0', substitutions: {} });

  const { text } = useAb({
    skip: !offer || !offerLink.link,
    uuid: String(offer?.id),
    testName: 'price-buttons-offer-provider',
    position: 'price-buttons-offer-provider',
    event,
    origin,
    forceCta: 'click_text',
    forceText: offer.clickText, // override a/b with this
    clicked,
    eventProperties: { elementType, ...omit(offerLink, 'substitutions') },
    substitutions: offerLink.substitutions,
    fallbackText: 'View Details',
  });

  React.useEffect(() => {
    const random = String(Math.floor(Math.random() * 10 ** 8));
    const link = offer?.url ? utmize(offer.url, 'price-buttons-offer', random, campaign) : '';
    const partnerType = seller?.type || '';
    setOfferLink({ link, seller: seller?.name, partnerType, random, substitutions: { release: releaseName, seller: seller?.name || '' } });
  }, [offer, seller, releaseName, campaign]);

  return (
    <Disclosure
      defaultOpen={defaultOpen}
      as="li"
      className={tw('rounded-xl border border-navy-10 bg-white text-navy shadow-xs', index === 3 && 'border-navy/60', className)}
    >
      {({ open }) => (
        <>
          <Disclosure.Button className="flex h-[68px] w-full justify-between">
            <div className="flex gap-5 self-stretch @2xl/prices:flex-[3.8_1_0] @5xl/prices:flex-[10_1_0]">
              <div className={tw('flex items-center border-r border-navy-10 px-4 laptop:px-5', index === 3 && 'border-navy/60')}>
                <IconChevronUp className={!open ? 'rotate-180' : ''} />
              </div>
              <div className="relative flex flex-col justify-center @2xl/prices:flex-row @2xl/prices:items-center">
                <div className={`text-left ${isProductPage ? 'laptop:pr-[400px]' : ''}`}>
                  <span className="-tracking-[0.54px] @2xl/prices:text-[18px]">{seller?.name}</span>
                </div>
                {isProductPage && (
                  <span className="absolute left-[225px] hidden text-left text-[16px] -tracking-[0.54px] text-navy/60 laptop:left-[275px] laptop:block">
                    {seller?.tagline}
                  </span>
                )}
                <div className="mt-[2px] flex @2xl/prices:hidden">
                  {seller?.tagline && (
                    <span className="mr-[10px] rounded-full bg-navy/5 px-[10px] py-1 text-[10px] leading-[130%] tracking-tight @2xl/prices:text-[12px]">
                      {seller?.tagline}
                    </span>
                  )}
                  <span className="mr-4 leading-[120%] tracking-tight @2xl/prices:text-[18px]">{offer?.price}</span>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-between self-stretch pr-5 @2xl/prices:flex-[6.2_1_0] @5xl/prices:flex-[8_1_0]">
              <div className="hidden items-center @2xl/prices:flex">
                {seller.rating && (
                  <>
                    <TrustPilotStar width="23" className="shrink-0" />
                    <span className="ml-[10px] text-[16px] -tracking-[0.48px]">{seller.rating} out of 5</span>
                  </>
                )}
              </div>
              <div className="flex items-center justify-between">
                {offer.tagline && (
                  <span className="mr-3 hidden rounded-full bg-navy/5 px-[10px] py-1 text-[12px] leading-[130%] tracking-tight @2xl/prices:block">
                    {offer.tagline}
                  </span>
                )}
                <span className="mr-4 hidden text-[18px] leading-[120%] tracking-tight @2xl/prices:block">
                  {offer?.price}
                  {/monthly/.test(offer.priceType) ? ' / pair / mth' : '/ pair'}
                </span>
                <Button.LinkPrimary
                  shouldOpenNewTab
                  onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                    e.stopPropagation();
                    setClicked((count) => count + 1);
                  }}
                  href={offerLink.link}
                  className="mt-0 min-h-0 w-full px-[12px] py-[6px] text-[14px] leading-[140%] tracking-tight @2xl/prices:text-[16px] laptop:w-auto laptop:min-w-0 laptop:px-[18px] laptop:py-[10px]"
                >
                  {text}
                </Button.LinkPrimary>
              </div>
            </div>
          </Disclosure.Button>
          <Disclosure.Panel className="">
            <div className={tw('border-t border-navy-10 px-[20px] py-[20px] @5xl/prices:p-[30px] @5xl/prices:pr-[60px]', index === 3 && 'border-navy/60')}>
              <PanelVariantOne seller={seller} offer={offer} releaseName={releaseName} releaseSlug={releaseSlug} campaign={campaign} />
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

interface LevelsListboxProps {
  releaseName: string;
  levels: OfferLevel[];
  selectedLevel: OfferLevel;
  onChange: (value: OfferLevel) => void;
}

const toPriceDisplay = (price: string): string => {
  const num = parseFloat((price || '0').replace(/[^0-9.]/g, ''));
  return num > 0 ? `From ${price}` : 'No offers';
};

const LevelsListbox: React.FC<LevelsListboxProps> = ({ releaseName, levels, onChange, selectedLevel }) => {
  return (
    <Listbox as="div" className="relative w-full" onChange={onChange}>
      <Listbox.Button className="relative flex w-full items-center justify-between rounded-[10px] border border-navy/10 bg-white p-[0.85rem] text-left">
        <span className="mr-2 block truncate text-[16px] font-light -tracking-[0.48px] text-navy">
          {releaseName} {selectedLevel.name} - {toPriceDisplay(selectedLevel.startingPrice)}
        </span>
        <IconChevronDownSm width={null} height={null} viewBox="0 0 10 6" className="w-[14px]" />
      </Listbox.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Listbox.Options className="absolute right-0 z-10 w-full origin-top-right divide-y divide-neutral-300 overflow-hidden rounded-xl border border-neutral-300">
          {levels.map((level, index) => (
            <Listbox.Option key={level.id} value={level}>
              {({ active }) => (
                <button
                  className={tw(
                    'w-full p-3 text-left text-[16px] font-light -tracking-[0.48px]',
                    index === levels.length - 1 && 'rounded-b-xl',
                    active ? 'bg-lapis text-white' : 'bg-white text-navy'
                  )}
                >
                  {releaseName} {level.name} - {toPriceDisplay(level.startingPrice)}
                </button>
              )}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Transition>
    </Listbox>
  );
};

interface PricesProps {
  release: Release;
  className?: ClassNameValue;
  isProductPage: boolean;
}

// Remember: in React state doesn't get reset when props change
// You have to manually do it in useEffect. So either put everything
// in state and change it all with useEffect or use props directly
// and only change state for things that are changed in this component.
export const Prices: React.FC<PricesProps> = ({ release, className, isProductPage }) => {
  const [isShowing, setIsShowing] = useState(true);
  const [selectedLevel, setSelectedLevel] = useState<OfferLevel>();
  const [campaign, setCampaign] = useState('');

  React.useEffect(() => {
    const itemName = `ht-ab-prices-open`;
    let seg;
    if (typeof localStorage !== 'undefined' && localStorage.getItem(itemName)) {
      seg = parseInt(localStorage.getItem(itemName)!, 10);
    }
    if (typeof seg === 'undefined' || seg === null || seg < 0 || seg > 1) {
      seg = Math.floor(Math.random() * 2);
      if (typeof localStorage !== 'undefined') {
        localStorage.setItem(itemName, seg.toString());
      }
    }
    setCampaign(seg === 0 ? 'prices-open' : 'prices-closed');
  }, []);

  const filteredOffers: Offer[] = useMemo(() => {
    const bySeller: Offer[] = [];
    const sellers = release?.prices?.sellers || {};
    if (selectedLevel?.id && release?.prices?.offers) {
      const levelId = selectedLevel.id;
      const offers = release.prices.offers[levelId] || [];
      if (offers.length) {
        offers.forEach((offer) => {
          if (!bySeller.some((o) => o.sellerId === offer.sellerId)) {
            bySeller.push(offer);
          }
        });
      }
    }
    return bySeller.sort((a, b) => {
      const pos = Math.sign(sellers[a.sellerId].position - sellers[b.sellerId].position);
      if (pos !== 0) {
        return pos;
      }
      return Math.sign(parseFloat(a.price.replace(/[^0-9]/g, '')) - parseFloat(b.price.replace(/[^0-9]/g, '')));
    });
  }, [selectedLevel, release?.prices]);

  React.useEffect(() => {
    setSelectedLevel(release?.prices?.levels?.[0] || undefined);
  }, [release?.prices]);

  const onTabClick = useCallback(() => {
    setIsShowing(false);
    setTimeout(() => setIsShowing(true), 100);
  }, []);

  return (
    <div className="@container/prices">
      <div
        className={tw(
          'flex flex-col justify-between gap-4 @2xl/prices:flex-row @2xl/prices:items-center laptop:gap-0',
          className,
          isProductPage ? 'some-stuff' : 'other-stuff'
        )}
      >
        <p className="pb-3 text-[24px] font-medium leading-[120%] tracking-tight laptop:text-[32px]">Pair Prices</p>
        <div className="flex gap-4 laptop:gap-0">
          {selectedLevel && filteredOffers && (
            <LevelsListbox
              releaseName={release.full_name}
              levels={release?.prices?.levels || []}
              selectedLevel={selectedLevel}
              onChange={(value: OfferLevel) => {
                if (value.id === selectedLevel.id) {
                  return;
                }
                setSelectedLevel(value);
                onTabClick();
              }}
            />
          )}
          {/* <input
            className="w-full rounded-xl px-[12px] py-[18px] text-[16px] font-light leading-5 shadow-[0_0_0_1px_#E7E9EC] focus:shadow-[0_0_0_2px_#4A7FE5] laptop:ml-4 laptop:w-[292px] laptop:px-4 laptop:py-3"
            type="text"
            placeholder="Refine results by location"
          /> */}
          <div className="rounded-full border border-white bg-navy p-[15px] laptop:ml-2">
            <IconArrowRight className="text-white" />
          </div>
        </div>
      </div>
      <div className="mt-4 laptop:mt-8">
        <Transition
          as={Fragment}
          show={isShowing}
          enter="transform transition duration-[400ms]"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transform duration-200 transition ease-in-out"
          leaveFrom="opacity-100 rotate-0 scale-100"
          leaveTo="opacity-0 scale-95 "
        >
          <ul className="flex flex-col gap-4">
            {(filteredOffers || []).map(
              (offer, index) =>
                release?.prices?.sellers[offer.sellerId] && (
                  <ProviderRow
                    defaultOpen={filteredOffers.length === 1 || (campaign === 'prices-open' && index === 0)}
                    key={offer.id}
                    offer={offer}
                    releaseName={release.name}
                    releaseSlug={release.slug}
                    seller={release?.prices?.sellers[offer.sellerId]}
                    className=""
                    index={index}
                    isProductPage={isProductPage}
                    campaign={campaign}
                  />
                )
            )}
          </ul>
        </Transition>
      </div>
    </div>
  );
};
