/* eslint no-console: 'off' */
// import util from 'util';
import { createFormat } from '@edge-runtime/format';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { isDevelopment, isProduction } from 'lib/ts-utils';

dayjs.extend(utc);
dayjs.extend(timezone);

const format = createFormat();
const logLevel = typeof window !== 'undefined' ? new URLSearchParams(window.location.search).get('logLevel') || 'warn' : process?.env?.LOG_LEVEL || 'warn';
const logCategory = typeof window !== 'undefined' ? new URLSearchParams(window.location.search).get('logCategory') : process?.env?.LOG_CATEGORY;

// Note that logLevel and LogCategory need to be set in .env to affect server-side rendering.

const levels = ['error', 'warn', 'info', 'debug', 'silly'];

const REMOTE_BATCH_SIZE = 1;
const remoteCache = [];

// Workaround due to the fact that vercel combines all logs from a request
// into a single entry but then truncates from the beginning so you lose
// the most important part. This logs directly to our server. Logs may be out
// of order and probably wouldn't do this in prod, but good for debugging.

// Note: need to set client_body_buffer_size and client_max_body_size in
// nginx or you'll get blanks for the request_body
const logRemotely = async (args) => {
  if (isDevelopment) {
    return null;
  }
  const remote = /x-remote/.test(args);
  if (!remote && typeof window !== 'undefined') {
    return null;
  }

  const line = { timestamp: dayjs().toISOString(), message: args };
  const lines = [];
  if (/x-remote/.test(args)) {
    lines.push(line);
  } else {
    remoteCache.push(line);
    if (remoteCache.length > REMOTE_BATCH_SIZE || dayjs(remoteCache[0].timestamp).diff(line.timestamp, 's') > 60) {
      lines.push(...remoteCache.splice(0, REMOTE_BATCH_SIZE));
    }
  }
  if (lines.length) {
    const body = lines.map((l) => JSON.stringify(l)).join('\n');
    const clause = remote ? '?remote=true' : '';
    // const ref = process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF || process.env.VERCEL_GIT_COMMIT_REF;
    const key = process.env.NEXT_PUBLIC_REMOTE_LOG_KEY || process.env.REMOTE_LOG_KEY;
    try {
      // const now = new Date();
      const url = isProduction ? `https://logs.hearingtracker.com${clause}` : `https://logs-staging.hearingtracker.com${clause}`;
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-VERCEL-KEY': key,
        },
        body,
      };
      // console.log('='.repeat(80));
      console.log('remote-log: logging remotely: %s: %d', url, lines.length);
      fetch(url, options)
        .then((res) => res.text().then((t) => console.log('remote-log response: %o', t)))
        .catch((ex) => console.error('remote-log error: %o', ex));
      // const result = await response.text();
      // console.log('took: %s', new Date() - now);
      // console.log('response: %s', result);
      // const time = dayjs().tz('America/Chicago').format();
      // console.log('time: %o', time);
      // console.log('='.repeat(80));
      return null;
    } catch (ex) {
      console.error('error sending logs: %o', ex);
    }
  }
  return null;
};

const IGNORES = [
  { message: 'Minified React error #418', paths: ['resources/truhearing'] }, // phone number on ios issue
  { message: 'Minified React error #423', paths: ['resources/truhearing'] },
  { message: 'ResizeObserver loop' },
  /*
  { message: 'Minified React error #423',
    paths: [
      '/hearing-aids/starkey-genesis-ai',
      '/news/jabra-enhance-plus-otc-hearing-aid-to-be-discontinued',
      */
];

const ignoreLog = (log) => {
  let message = null;
  let path = null;
  if (typeof log === 'string') {
    message = log;
    path = log;
  } else if (typeof log === 'object') {
    message = log.message || '';
    path = log.path || '';
  } else {
    return false;
  }
  return IGNORES.some((i) => message.indexOf(i.message) >= 0 && (!i.paths || i.paths.some((p) => path.indexOf(p) >= 0)));
};

let lastRemoteLog = null;
export const logRemotelyClient = async (...args) => {
  try {
    if (ignoreLog(args)) {
      return null;
    }
    const [message, ...rest] = args;
    const time = dayjs().tz('America/Chicago').format();
    const requestId = document && document.querySelector && document.querySelector('meta[name="requestId"]')?.content;
    const body = format(
      `[x-client]|${time}|${requestId}|${message}|${window.location.href}|${navigator.userAgent}|${navigator.language}|cookies:${navigator.cookieEnabled}`,
      ...rest
    );
    if (isDevelopment) {
      console.log('not logging remotely client: %o', body);
      return null;
    }
    const ts = new Date();
    if (lastRemoteLog && ts - lastRemoteLog < 100) {
      return null;
    }
    lastRemoteLog = ts;
    console.log('logging error remotely: %o', body);
    const url = isProduction ? 'https://logs.hearingtracker.com' : 'https://logs-staging.hearingtracker.com';
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Vercel-Key': process.env.REMOTE_LOG_KEY,
      },
      body,
    };
    return fetch(url, options);
  } catch (ex) {
    console.error('error sending logs: %o', ex);
  }
  return null;
};

const genId = () => [...Array(20)].map(() => String.fromCharCode(Math.floor(Math.random() * 26) + 65)).join('');

const logger = ({ category, request = null }) => {
  const requestId = request?.headers?.['x-vercel-id'] || genId();
  const metadata = [requestId];
  if (request?.path) {
    metadata.push(request.path);
  }
  if (request?.ip) {
    metadata.push(request.ip);
  }
  const meta = `[${metadata.join('|')}]`;
  const l = {
    category,
    level: logLevel || undefined,
    requestId,

    isLogging: (checkLevel) => levels.indexOf(checkLevel) <= levels.indexOf(logLevel),

    solo: (...args) => {
      // Use solo when you are in dev and want to just log a specific line without
      // logging everything else. Or I guess use console.log instead.
      if (logLevel === 'solo') {
        const [message, ...rest] = args;
        console.log(`[solo] ${category}: ${message}${meta}`, ...rest);
      }
      return true;
    },

    silly: (...args) => {
      if (logLevel === 'silly' && (logCategory || category) === category) {
        const [message, ...rest] = args;
        console.debug(`[silly] ${category}: ${message}${meta}`, ...rest);
      }
      return true;
    },

    debug: (...args) => {
      if (/silly|debug/.test(logLevel) && (logCategory || category) === category) {
        const [message, ...rest] = args;
        console.debug(`[debug] ${category}: ${message}${meta}`, ...rest);
        // logRemotely(format(`[x-debug] ${category}: ${message}${meta}`, ...rest));
      }
      return true;
    },

    info: (...args) => {
      if (/silly|debug|info/.test(logLevel) && (logCategory || category) === category) {
        const [message, ...rest] = args;
        const time = dayjs().tz('America/Chicago').format();
        console.log(`[info] ${time} ${category}: ${message}${meta}`, ...rest);
        // return logRemotely(format(`[x-info]|${time}|${category}|${message}|${meta}`, ...rest));
      }
      return true;
    },

    // Force log remotely for tracking client stuff
    remote: (...args) => {
      const [message, ...rest] = args;
      console.log(`[info] ${category}: ${message}${meta}`, ...rest);
      if (isDevelopment) {
        return null;
      }
      return logRemotely(format(`[x-remote] ${category}: ${message}${meta}`, ...rest));
    },

    warn: (...args) => {
      if (logLevel !== 'error' && (logCategory || category) === category) {
        const [message, ...rest] = args;
        const time = dayjs().tz('America/Chicago').format();
        console.warn(`[warn] ${time} ${category}: ${message}`, ...rest);
        if (isDevelopment) {
          return null;
        }
        return logRemotely(format(`[x-warn]|${time}|${category}|${message}|${meta}`, ...rest));
      }
      return null;
    },

    error: (...args) => {
      if ((logCategory || category) === category) {
        const [message, ...rest] = args;
        const time = dayjs().tz('America/Chicago').format();
        console.error(`[error] ${time} ${category}: ${message}`, ...rest);
        if (isDevelopment) {
          return null;
        }
        return logRemotely(format(`[x-error]|${time}|${category}|${message}|${meta}`, ...rest));
      }
      return null;
    },
  };
  return l;
};

export default logger;
