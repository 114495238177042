import snakeCase from 'lodash/snakeCase';
import ph from 'posthog-js';

import { isDevelopment, isProduction } from 'lib/ts-utils';

// dumb typescript module resolution requires this
const posthog = ph;

let initialized = false;

const clean = (key: string) => snakeCase(key.replace(/^event/, ''));

interface TrackProps {
  event: string;
  eventType: string;
  origin: string;
  properties?: Record<string, string | boolean | number | Date | undefined>;
}

let importing = false;
const startAhoy = async () => {
  if (typeof window.ahoy !== 'undefined') {
    // eslint-disable-next-line no-console
    console.debug('ahoy already started');
    return;
  }
  if (importing) {
    // eslint-disable-next-line no-console
    console.debug('already importing, waiting');
    return new Promise((rslv) => {
      setTimeout(() => rslv(true), 500);
    });
  }
  return new Promise((resolve) => {
    // eslint-disable-next-line no-console
    console.log('importing ahoy');
    try {
      importing = true;
      import('ahoy.js').then(({ default: ahoyJs }) => {
        // eslint-disable-next-line no-console
        console.log('imported ahoy');
        // eslint-disable-next-line no-console
        console.debug('ahoy loaded');
        ahoyJs.configure({
          visitsUrl: '/blimey/visits',
          eventsUrl: '/blimey/events',
          // cookieDomain: 'hearingtracker.com', don't think we need this if using JS
        });
        // eslint-disable-next-line no-console
        console.debug('ahoy ready');
        window.ahoy = ahoyJs;
        if (!isProduction) {
          ahoyJs.debug();
        }
        resolve(true);
      });
    } catch (ex) {
      // eslint-disable-next-line no-console
      console.error(ex);
      // don't want to blow everything up over this
      resolve(false);
    }
  });
};

const ahoyTrack = async ({ event, eventType, origin, properties }: TrackProps) => {
  if (typeof window.ahoy === 'undefined') {
    // eslint-disable-next-line no-console
    console.debug('ahoy not defined starting it');
    const result = await startAhoy();
    // eslint-disable-next-line no-console
    console.debug('back from starting: %o', result);
  }
  if (typeof window.ahoy === 'undefined') {
    // eslint-disable-next-line no-console
    console.debug('Could not start ahoy');
  } else {
    // eslint-disable-next-line no-console
    console.debug('ahoy available, tracking %s', event);
    window.ahoy.track(event, { ...properties, eventType, origin });
  }
  /*
  fetchApi({
    path: 'blimey/event',
    method: 'POST',
    variables: { name: event, properties: { ...properties, eventType } },
    fallback: null,
    origin,
  });
  */
};

export const record = ({ action }: { action: string }) => {
  if (typeof posthog !== 'undefined') {
    if (action === 'start' && posthog.startSessionRecording) {
      posthog.startSessionRecording();
    } else if (action === 'stop' && posthog.stopSessionRecording) {
      posthog.stopSessionRecording();
    }
  }
};

export const track = async ({ event, eventType, origin, properties }: TrackProps) => {
  if (!isProduction) {
    // eslint-disable-next-line no-console
    console.debug('track: %o', { event, eventType, origin, properties });
  }
  if (isDevelopment) {
    // eslint-disable-next-line no-console
    console.debug('Skipping tracking in dev');
    // return;
  }
  // eslint-disable-next-line @typescript-eslint/dot-notation
  if (typeof window !== 'undefined') {
    const path = window.location.pathname;
    // trackEvent(event, { props: { ...properties, path } }); // Plausible
    if (typeof window.gtag !== 'undefined') {
      window.gtag('event', eventType, { ...properties, path }); // GA4
    }
    if (eventType !== 'impression' && typeof posthog !== 'undefined' && posthog.capture) {
      // eslint-disable-next-line no-console
      console.debug('sending ph event: %s', event);
      posthog.capture(event, { ...properties, eventType, path, origin });
    } else if (eventType !== 'impression') {
      // eslint-disable-next-line no-console
      console.debug('no posthog in scope, not sending ph event: %s', event);
    }
    ahoyTrack({ event, eventType, properties: { ...properties, path }, origin }); // us
  }
};

export const setupTracking = () => {
  if (initialized) {
    return;
  }
  initialized = true;
  // Note: this only works on <a><span>... because we have pointer-events set to none
  // in styles/site.scss for a[data-track] elements.
  document.addEventListener('click', (e) => {
    if (e.target instanceof HTMLAnchorElement) {
      const element = e.target as HTMLAnchorElement;
      const { track: isTracking, eventName, origin, ...rest } = element.dataset;
      if (isTracking) {
        const path = window.location.pathname;
        const properties = Object.entries({ url: element.href, ...rest })
          .filter(([key]) => /event/.test(key))
          .reduce((h, [key, value]) => Object.assign(h, { [clean(key)]: value }), {});
        track({ event: `${eventName}--click` || element.id || 'click', eventType: 'click', origin: origin || path, properties });
      }
    }
  });
};

export const startTrackingObserver = () => {
  if (!isProduction) {
    // eslint-disable-next-line no-console
    console.debug('Starting tracking observer');
  }
  const intersectionObserver = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      const el = entry.target as HTMLAnchorElement;
      if (el && entry.isIntersecting && el.checkVisibility && el.checkVisibility()) {
        if (!isProduction) {
          // eslint-disable-next-line no-console
          console.debug('Intersection: %o, dataset: %o', el, { ...el.dataset });
        }
        if (!el.dataset.seen) {
          el.dataset.seen = 'true';
          const { eventName, origin, ...rest } = el.dataset;
          const path = window.location.pathname;
          const properties = Object.entries({ url: el.href, ...rest })
            .filter(([key]) => /event/.test(key))
            .reduce((h, [key, value]) => Object.assign(h, { [clean(key)]: value }), {});
          track({ event: `${eventName}--impression`, eventType: 'impression', origin: origin || path, properties });
        }
      }
    });
  });
  const tracked: HTMLElement[] = [];
  document.querySelectorAll('[data-track-impression]').forEach((el) => {
    (el as HTMLElement).dataset.watching = 'true';
    tracked.push(el as HTMLElement);
    intersectionObserver.observe(el);
  });
  if (!isProduction) {
    // eslint-disable-next-line no-console
    console.debug('Tracked: %o', tracked);
  }
  return { intersectionObserver, tracked };
};

export const stopTrackingObserver = (observer: { intersectionObserver: IntersectionObserver; tracked: HTMLElement[] }) => {
  if (!isProduction) {
    // eslint-disable-next-line no-console
    console.debug('Stopping tracking observer');
  }
  const { intersectionObserver } = observer;
  intersectionObserver.disconnect();
};
