import { ClassNameValue } from 'tailwind-merge';

import IconAndroid from 'images/icon-android.svg';
import IconApple from 'images/icon-apple.svg';
import IconHandsFree from 'images/icon-hands-free.svg';
import IconIpRating from 'images/icon-ip-rating.svg';
import IconRechargable from 'images/icon-rechargable.svg';
import IconTelecoil from 'images/icon-telecoil.svg';
import { tw } from 'lib/utils';

import styles from './product-features.module.scss';

const KEY_FEATURES = [
  { id: 'Hands-Free', name: 'Hands-free calling', icon: <IconHandsFree /> },
  { id: 'Rechargeable', name: 'Rechargeable', icon: <IconRechargable /> },
  { id: 'iPhone Streaming', name: 'iPhone Streaming', icon: <IconApple /> },
  { id: 'Android Streaming', name: 'Android Streaming', icon: <IconAndroid /> },
  // Wasn't able to manipulate the svg to look like the others so added these classes which seem close
  { id: 'Telecoil', name: 'Telecoil', icon: <IconTelecoil className="mt-[8px] scale-[2.0]" /> },
];

interface ProductFeaturesProps {
  keyFeatures: string[];
  className?: ClassNameValue;
  listClassName?: ClassNameValue;
}

export const ProductFeatures: React.FC<ProductFeaturesProps> = ({ keyFeatures, className, listClassName }) => {
  const ipRating = keyFeatures?.find((f) => /^IP/.test(f));

  return (
    <section className={tw('py-6', className)}>
      <div className="text-xl font-[300] tracking-tight text-navy">Product Features</div>
      <ul className={tw(styles['product-feature-list'], 'gap-y-5', listClassName)}>
        {KEY_FEATURES.map(({ id, name, icon }) => (
          <li key={id} className={styles['product-feature-list-item']}>
            <div className={styles['product-feature-list-left']}>{icon}</div>
            <div>
              <div className={styles['product-feature-list-item-label']}>{name}</div>
              <div className={styles['product-feature-list-item-value']}>{keyFeatures?.find((f) => f === id) ? 'Yes' : 'No'}</div>
            </div>
          </li>
        ))}
        <li className={styles['product-feature-list-item']}>
          <div className={styles['product-feature-list-left']}>
            <IconIpRating />
          </div>
          <div>
            <div className={styles['product-feature-list-item-label']}>IP Rating</div>
            <div className={styles['product-feature-list-item-value']}>{ipRating || 'N/A'}</div>
          </div>
        </li>
      </ul>
    </section>
  );
};
