import React from 'react';

import { type SbBlokData, StoryblokComponent, storyblokEditable } from '@storyblok/react';

import type { ABVariantStoryblok } from 'types/storyblok-component-types';

interface StoryContent {
  blocks: SbBlokData[];
}

interface Story {
  content: StoryContent;
  cv: number;
}

interface ABVariantProps {
  blok: ABVariantStoryblok;
  story: Story;
  variant: string;
}

const ABVariant: React.FC<ABVariantProps> = ({ blok, story, variant, ...props }) => {
  if (!blok.blocks?.length) {
    return null;
  }
  const b = variant === 'B' ? blok.blocks[1] : blok.blocks[0];
  if (b) {
    return <StoryblokComponent blok={b} story={story} {...props} key={b._uid} {...storyblokEditable(b)} />;
  }
  return null;
};

export default ABVariant;
