// example: /otc-hearing-aids, /best-hearing-aids
/* eslint-disable no-unused-vars */
import * as React from 'react';

import { storyblokEditable } from '@storyblok/react';

import { HEARING_AID_MODEL_SCORE_TOTAL } from 'components/common/constants';
import { CircleScoreFluid } from 'components/common-n4/circle-score-fluid';
import HtLink from 'components/common-n4/ht-link';
import ImageWrapper from 'components/common-n4/image';
import { ProductInfoPopup } from 'components/common-n4/product-info/product-info-popup';
import PriceButton from 'components/widgets/price-button';
import { useApiData } from 'hooks';
import { fetchApi } from 'lib/ht_api';
import logger from 'lib/logger';
import { normalizeUrl } from 'lib/utils';
import { Model } from 'types/release';
import { N4ProductCardStoryblok } from 'types/storyblok-component-types';

const log = logger({ category: 'n4/ListicleProduct' });

const extractModel = ({ blok, models }: { blok: N4ProductCardStoryblok; models: Record<string, Model> }): Model | null => {
  if (blok.form_factor) {
    return models[blok.form_factor];
  }
  if (blok.hearing_aid) {
    return Object.values(models).find((m) => m.hearing_aids?.some((ha) => String(ha.id) === String(blok.hearing_aid))) || models[`ha-${blok.hearing_aid}`];
  }
  return null;
};

interface ListicleProductProps {
  blok: N4ProductCardStoryblok;
}

const ListicleProduct: React.FC<ListicleProductProps> = ({ blok, ...props }) => {
  log.debug('blok: %o', { blok, props });
  const { models } = useApiData();
  const [model, setModel] = React.useState(extractModel({ blok, models }));
  const [isProductInfoShowing, setIsProductInfoShowing] = React.useState(false);

  React.useEffect(() => {
    if (!blok.form_factor && !blok.hearing_aid) {
      setModel(null);
      return;
    }
    const newModel = extractModel({ blok, models });
    if (newModel) {
      setModel(newModel);
      return;
    }
    const fn = async () => {
      const variables = { ids: [blok.form_factor], hearingAidIds: [blok.hearing_aid] };
      const result = await fetchApi({ path: 'releases/models', variables, fallback: {}, origin: 'bloks/n4/product-card' });
      setModel(extractModel({ blok, models: result }));
    };
    fn();
  }, [blok, models]);

  const hearingAid = model?.hearing_aids?.[0];

  if (!model) {
    return null;
  }

  if (!hearingAid) return null;

  // const score = model.release_score || model.score;
  // const price = blok.form_factor ? model.price : hearingAid.price;
  const image = blok.image?.filename ? blok.image : model.image;

  return (
    <>
      <section
        className="listicle-section mb-8 flex flex-col self-stretch lg:block"
        {...storyblokEditable(blok)}
        data-form-factor={blok.form_factor || ''}
        data-hearing-aid={blok.hearing_aid || ''}
        id={blok._uid}
      >
        <div className="mx-none order-1 mt-6 max-w-none items-end justify-between lg:mt-0 lg:flex laptop:mx-auto laptop:max-w-[810px]">
          <div className="flex flex-col gap-[4px]">
            <div className="text-[16px] leading-[1] tracking-tight text-navy lg:text-[20px]">{blok.name}</div>
            <div className="text-[24px] font-medium leading-[120%] tracking-tight text-navy lg:text-[32px]">
              <HtLink href={normalizeUrl({ url: model.path, origin: `listicle-product-${model.slug}` })}>{model.full_name}</HtLink>
            </div>
            <div className="text-[18px] tracking-tight text-lapis lg:text-[20px] lg:leading-[1]">
              <PriceButton
                release={model.release}
                model={model}
                origin="listicle-product"
                position="listicle-product-within-article-link"
                display="link"
                prices={model.release.prices}
                useFallback
              />
            </div>
          </div>
          <div className="mt-6 lg:mt-0 laptop:mt-12">
            <PriceButton
              release={model.release}
              prices={model.release.prices}
              model={model}
              noGeo
              origin="listicle-product"
              position="listicle-product-within-article"
              className="min-h-[40px] !w-full whitespace-nowrap !px-[1.4rem] !py-[0.8rem] !text-[16px]"
              forceDirect
            />
          </div>
        </div>
        <div className="order-0 mx-none relative max-w-none lg:mt-6 laptop:mx-auto laptop:max-w-[810px]">
          <ImageWrapper
            image={image}
            origin="listicle-product"
            imgProps={{ className: 'rounded-[20px] !w-full !h-[250px] xs:!h-[260px] sm:!h-[350px] md:!h-[400px] lg:!h-[500px] laptop:!h-[456px] object-cover' }}
          />
          {model.score && (
            <div className="absolute left-6 top-6">
              <CircleScoreFluid progress={(model.score / HEARING_AID_MODEL_SCORE_TOTAL) * 100} amount={model.score} size="md" origin="listicle-product" />
            </div>
          )}
          <div className="absolute bottom-4 right-4">
            <button onClick={() => setIsProductInfoShowing(true)} className="block rounded-[10px] bg-white px-5 py-3 text-md text-black drop-shadow-md">
              View product details
            </button>
          </div>
        </div>
      </section>
      <ProductInfoPopup
        releaseSlug={model?.release.slug}
        modelId={model?.id}
        useModel={!!model?.id}
        isOpen={isProductInfoShowing}
        setIsOpen={setIsProductInfoShowing}
      />
    </>
  );
};

export default ListicleProduct;
